import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Seo from "../components/seo/seo"
import CSHero from "../components/hero/csHero/csHero"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"

import { findAvailableModule } from "../helpers/findAvailableModule"

import * as styles from "./case-study.module.scss"

// It's imported this way because it's also used in gatsby-node
const generatePath = require("../helpers/generatePath")

const CaseStudy = ({ data, pageContext }) => {
  const { content, seo } = data.sanityCs.tabs
  const translations = data.sanityTranslationCs?.translation
  const enPathUrl = translations && generatePath(translations[0])
  const esPathUrl = translations && generatePath(translations[1])
  // const auPathUrl = translations && generatePath(translations[2])
  // const ptPathUrl = translations && generatePath(translations[3])
  const pageBuilder = [
    ...(content._rawPageBuilder || []),
    ...(content._rawCta ? [content._rawCta] : []),
    ...(content._rawFurtherReading ? [content._rawFurtherReading] : []),
  ]
  const link = content.market === "en-us" ? "case-studies" : `${content.market}/case-studies`
  const { _updatedAt, _createdAt } = data.sanityCs
  const image = content?._rawHero?.company_image || ""

  const steps = [
    {
      link,
      title: "Case Studies",
    },
    {
      link: `${link}/${content.category.slug.current}`,
      title: content.category.title,
    },
  ]

  const availableFaq = findAvailableModule(content._rawPageBuilder, "faq", "faqNoImage")

  return (
    <Layout
      translations={translations}
      className={styles.caseStudies}
      enPathUrl={enPathUrl}
      esPathUrl={esPathUrl}
      // auPathUrl={auPathUrl}
      // ptPathUrl={ptPathUrl}
    >
      <Seo
        title={content.title}
        isBlogPost={false}
        image={image?.asset?.url}
        path={generatePath(pageContext.page)}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        breadcrumbs={steps}
        faq={availableFaq}
        {...seo}
      />
      {content._rawHero ? <CSHero {...content._rawHero} /> : <Breadcrumbs homePageLabel="case studies" />}
      <PageBuilder pageBuilder={pageBuilder} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!, $_id: String!) {
    sanityCs(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _id
      _updatedAt(formatString: "MMMM Do, YYYY")
      _createdAt(formatString: "MMMM Do, YYYY")
      tabs {
        content {
          title
          market
          _rawHero(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
          _rawCta(resolveReferences: { maxDepth: 10 })
          _rawFurtherReading(resolveReferences: { maxDepth: 10 })
          category {
            title
            slug {
              current
            }
          }
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    sanityTranslationCs(translation: { elemMatch: { tabs: { content: {} }, _id: { eq: $_id } } }) {
      translation {
        _id
        _type
        tabs {
          content {
            market
            slug {
              current
            }
            category {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`

export default CaseStudy
