import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import RichText from "../../richText/richText"
import Section from "../../section/section"
import Breadcrumbs from "../../breadcrumbs/breadcrumbs"

import * as styles from "./csHero.module.scss"

function CSHero(props) {
  const { richText, background_image, company_image, background_title, company_name } = props
  const background = richText?.length && "grey-gradient"

  return (
    <Section background={background}>
      <div className={styles.heroWithBackround}>
        <div className={styles.backgroundImage}>
          <img src={background_image?.asset?.url} alt={background_title} />
        </div>
        <div className={styles.companyWrapper}>
          <div className={styles.companyBackgroundWrapper}>
            <Breadcrumbs className={styles.breadcrumbs} homePageLabel="case studies" fontColor="" />
            <div className={styles.companyBackground} />
          </div>
          <div className={classNames("container", styles.companyImage)}>
            <img src={company_image?.asset?.url} alt={company_name} />
          </div>
        </div>
        {!!richText?.length && (
          <h2 className="text-align-center mt-s">
            <RichText blocks={richText} />
          </h2>
        )}
      </div>
    </Section>
  )
}

CSHero.propTypes = {
  richText: PropTypes.arrayOf(PropTypes.any),
  background_image: PropTypes.objectOf(PropTypes.any).isRequired,
  company_image: PropTypes.objectOf(PropTypes.any).isRequired,
  background_title: PropTypes.string,
  company_name: PropTypes.string.isRequired,
}

CSHero.defaultProps = {
  richText: [],
  background_title: "",
}

export default CSHero
